import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MEDIA_FEATURE_KEY } from './keys';
import { mediaReducer } from './reducers/media.reducer';
import { MediaEffects } from './effects/media.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(MEDIA_FEATURE_KEY, mediaReducer), EffectsModule.forFeature([MediaEffects])],
})
export class MediaStoreModule {}
