import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { MediaFacade } from '../facades/media.facade';

export function mediaCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const mediaFacade = inject(MediaFacade);

        mediaFacade.clear();
        return true;
    };
}
