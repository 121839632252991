import { createReducer, on } from '@ngrx/store';
import { mediaActions } from '../actions/media.actions';

export interface MediaState {
    images: Record<string, { loaded: boolean | null; blob: Blob | null }>;
    assetsImages: Record<string, { loaded: boolean | null; blob: Blob | null }>;
}

export const initialState: MediaState = {
    images: {},
    assetsImages: {},
};

export const mediaReducer = createReducer(
    initialState,
    on(mediaActions.getImage, (state, payload): MediaState => {
        if (state.images[payload.id] === undefined) {
            const item = { loaded: null, blob: null };
            const images = { ...state.images, [payload.id]: item };

            return {
                ...state,
                images,
            };
        }

        return state;
    }),

    on(mediaActions.getImageSuccess, (state, payload): MediaState => {
        const item = { ...state.images[payload.id], loaded: true, blob: payload.blob };
        const images = {
            ...state.images,
            [payload.id]: item,
        };

        return {
            ...state,
            images,
        };
    }),

    on(mediaActions.getImageError, (state, payload): MediaState => {
        const item = { ...state.images[payload.id], loaded: false, blob: null };
        const images = {
            ...state.images,
            [payload.id]: item,
        };

        return {
            ...state,
            images,
        };
    }),

    on(mediaActions.getAssetsImage, (state, payload): MediaState => {
        if (state.assetsImages[payload.id] === undefined) {
            const item = { loaded: null, blob: null };
            const assetsImages = { ...state.assetsImages, [payload.id]: item };

            return {
                ...state,
                assetsImages,
            };
        }

        return state;
    }),

    on(mediaActions.getAssetsImageSuccess, (state, payload): MediaState => {
        const item = { ...state.assetsImages[payload.id], loaded: true, blob: payload.blob };
        const assetsImages = {
            ...state.assetsImages,
            [payload.id]: item,
        };

        return {
            ...state,
            assetsImages,
        };
    }),

    on(mediaActions.getAssetsImageError, (state, payload): MediaState => {
        const item = { ...state.assetsImages[payload.id], loaded: false, blob: null };
        const assetsImages = {
            ...state.assetsImages,
            [payload.id]: item,
        };

        return {
            ...state,
            assetsImages,
        };
    }),

    on(mediaActions.clear, (): MediaState => {
        return {
            ...initialState,
        };
    }),
);
