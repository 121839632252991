import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { mediaActions } from '../actions/media.actions';
import { selectImageUrl, selectLoaded, selectAssetsImageUrl, selectAssetsLoaded } from '../selectors/media.selector';

@Injectable({
    providedIn: 'root',
})
export class MediaFacade {
    private readonly store = inject(Store);

    readonly imageUrl$ = (id: string) => this.store.select(selectImageUrl(id));
    readonly imageLoaded$ = (id: string) => this.store.select(selectLoaded(id));
    readonly assetsImageUrl$ = (id: string) => this.store.select(selectAssetsImageUrl(id));
    readonly assetsImageLoaded$ = (id: string) => this.store.select(selectAssetsLoaded(id));

    getImage(id: string): void {
        this.store.dispatch(mediaActions.getImage({ id }));
    }

    getAssetsImage(id: string): void {
        this.store.dispatch(mediaActions.getAssetsImage({ id }));
    }

    clear() {
        this.store.dispatch(mediaActions.clear());
    }
}
