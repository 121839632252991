import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getImage(id: string): Observable<Blob> {
        const url = `api/v1/media/image/${id}`;

        return this.httpClient.addResponseCaching().get(url, { responseType: 'blob' });
    }

    getAssetsImage(value: string): Observable<Blob> {
        const url = `${environment.assetsUrl}api/v1${value}`;

        return this.httpClient.addResponseCaching().skipApiPrefix().get(url, { responseType: 'blob' });
    }
}
