import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const mediaActions = createActionGroup({
    source: 'Media',
    events: {
        'Get Image': props<{ id: string }>(),
        'Get Image Success': props<{ id: string; blob: Blob }>(),
        'Get Image Error': props<{ id: string }>(),

        'Get Assets Image': props<{ id: string }>(),
        'Get Assets Image Success': props<{ id: string; blob: Blob }>(),
        'Get Assets Image Error': props<{ id: string }>(),

        Clear: emptyProps(),
    },
});
