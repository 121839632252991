import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MediaState } from '../reducers/media.reducer';
import { MEDIA_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<MediaState>(MEDIA_FEATURE_KEY);

const selectLoaded = (id: string) => createSelector(selectState, (state) => state.images[id]?.loaded ?? null);
const selectImageUrl = (id: string) =>
    createSelector(selectState, (state) => {
        const blob = state.images[id]?.blob;

        return blob ? URL.createObjectURL(blob) : null;
    });

const selectAssetsLoaded = (id: string) => createSelector(selectState, (state) => state.assetsImages[id]?.loaded ?? null);
const selectAssetsImageUrl = (id: string) =>
    createSelector(selectState, (state) => {
        const blob = state.assetsImages[id]?.blob;

        return blob ? URL.createObjectURL(blob) : null;
    });

export { selectImageUrl, selectLoaded, selectAssetsLoaded, selectAssetsImageUrl };
